<!--
 * @Author: wyq
 * @Date: 2021-09-29 16:06:19
 * @LastEditTime: 2021-10-12 14:17:27
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\components\dialogs\questionResultDialog.vue
-->
<template>
  <dialog-template class="question-result-dialog" :visible.sync="dialogVisible" title="答题过程">
    <div slot="title">
      <div class="clock">
        <v-icon>icon-clock</v-icon>
        <span>{{questionInfo.costTime|formatSeconds}}</span>
      </div>
      <v-badge
        v-if="questionInfo.responseStatus==1"
        color="success"
        icon="icon-success"
        left
        offset-y="22"
      >
        <div class="tip">恭喜你，答对啦～</div>
      </v-badge>
      <v-badge v-else color="error" icon="icon-error" left offset-y="20">
        <div class="tip">很遗憾，答错了！</div>
      </v-badge>
    </div>
    <div>
      <div class="content-main">
        <question-stem :questionInfo="questionInfo"></question-stem>
      </div>
      <question-options :questionInfo="questionInfo"></question-options>
    </div>
  </dialog-template>
</template>
<script>
import dialogTemplate from './dialogTemplate'
import mixin from '@/utils/dialogMixin'
import questionStem from '../testing/questionStem.vue'
import questionOptions from '../testing/questionOptions'
export default {
  components: {
    dialogTemplate,
    questionStem,
    questionOptions,
  },
  mixins: [mixin],
  props: {
    questionInfo: Object,
  },
  data() {
    return {
      second: 10,
    }
  },
  // computed: {
  //   questionInfo() {
  //     let val = {
  //       title: '如图，右边长方体中由左边的平面图形围成的是$(\\quad)$.',
  //       type: 1,
  //       optionNum: 4,
  //       titlePhoto: [
  //         {
  //           name: '图1',
  //           url: 'https://adaptive-learning-system.obs.cn-south-1.myhuaweicloud.com/v1/MTYyNzAyNDg5MnNhM2FvY3l5NmQ=.png',
  //         },
  //       ],
  //       option1: '如图，',
  //       option1Photo: [
  //         {
  //           name: '图1',
  //           url: 'https://adaptive-learning-system.obs.cn-south-1.myhuaweicloud.com/v1/MTYyOTk2MDM1OXQ3bjZkcWFkN3c=.png',
  //         },
  //       ],
  //       option2: '如图，',
  //       option2Photo: [
  //         {
  //           name: '图1',
  //           url: 'https://adaptive-learning-system.obs.cn-south-1.myhuaweicloud.com/v1/MTYyOTk2MDM1OXQ3bjZkcWFkN3c=.png',
  //         },
  //       ],
  //       option3: '如图，',
  //       option3Photo: [
  //         {
  //           name: '图1',
  //           url: 'https://adaptive-learning-system.obs.cn-south-1.myhuaweicloud.com/v1/MTYyOTk2MDM1OXQ3bjZkcWFkN3c=.png',
  //         },
  //       ],
  //       option4: '如图，',
  //       option4Photo: [
  //         {
  //           name: '图1',
  //           url: 'https://adaptive-learning-system.obs.cn-south-1.myhuaweicloud.com/v1/MTYyOTk2MDM1OXQ3bjZkcWFkN3c=.png',
  //         },
  //       ],
  //     }
  //     let result = this.$formattedData(val)
  //     console.log(result)
  //     return result
  //   },
  // },
}
</script>
<style lang="scss" scoped>
.question-result-dialog {
  .content-title {
    position: relative;
    margin-bottom: 52px;
    font-size: 22px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    &::after {
      position: absolute;
      left: 0;
      bottom: -26px;
      content: '';
      width: 27px;
      height: 4px;
      background: #0148ff;
      border-radius: 2px;
    }
  }
  .content-main {
    padding: 0 28px;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 26px;
    border: 1px dashed rgba(#0148ff, 0.1);
  }
  .clock {
    display: inline-flex;
    font-size: 28px;
    color: #fff;
    font-family: Akrobat-Semibold;
    margin-right: 80px;
    .v-icon {
      color: #fff;
      margin-right: 18px;
    }
  }
  .tip {
    display: inline-block;
    margin-left: 20px;
    font-family: PingFangSC-Regular;
    font-size: 20px;
  }
}
</style>