<!--
 * @Author: yy
 * @Date: 2021-10-03 15:53:21
 * @LastEditTime: 2021-10-12 18:47:30
 * @LastEditors: yy
 * @Description: 
 * @FilePath: \cat_student\src\components\echarts\areamap.vue
-->
<template>
  <div id="areaMap"></div>
</template>

<script>
export default {
  props: {
    singleTime: Array,
    averageTime: Array,
  },
  computed: {
    getSingleTime() {
      return this.singleTime;
    },
  },
  watch: {
    getSingleTime() {
      if (this.singleTime) {
        this.getMyEcharts();
      }
    },
  },
  data() {
    return {
      xAxisData: [], //x轴
      singleData: [],
      averageData: [],
    };
  },
  methods: {
    getMyEcharts() {
      this.xAxisData = [];
      let s = JSON.parse(JSON.stringify(this.singleTime)) ;
      let sData = s.sort(function (a, b) {
        return a.order - b.order;
      });
      this.singleData = sData.map(res=>{
        this.singleData = res.time || 0;
        return this.singleData
      })
      let a = JSON.parse(JSON.stringify(this.averageTime)) ;
      let aData = a.sort(function (a, b) {
        return a.order - b.order;
      });
      this.averageData = aData.map(res=>{
        this.averageData = res.time || 0;
        return this.averageData
      })
      this.xAxisData = aData.map(res=>{
        this.xAxisData = res.order;
        return this.xAxisData
      })
      this.drawMyChart();
    },
    drawMyChart() {
      var myChart = this.echarts.init(document.getElementById("areaMap"));
      var option;
      option = {
        color: ['#0148ff', '#ffb13f'],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
            // label: {
            //   backgroundColor: "#6a7985",
            // },
          },
        },
        grid: {
          left: "0%",
          right: "1%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.xAxisData,
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            margin: 20,
            color: "#999",
            fontSize: 13,
          },
        },
        yAxis: {
          type: "value",
          show: true,
          // max: 180,
          axisLabel: {
            margin: 20,
            color: "#999",
            fontSize: 13,
          },
        },
        series: [
          {
            name: "个人答题时间",
            type: "line",
            stack: "Total",
            smooth: true,
            lineStyle: {
              color: "#0148ff",
              width: 3,
              shadowColor: "rgba(0, 0, 0, 0.03)",
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowOffsetY: 30,
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.5,
              color: new this.echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#a4c5ff",
                },
                {
                  offset: 1,
                  color: "#fff",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
            data: this.singleData,
          },
          {
            name: "平均答题时间",
            data: this.averageData,
            type: "line",
            smooth: true,
            symbolSize: 0, //标记点的大小
            lineStyle: {
              color: "rgba(255,177,63,0.4)",
              width: 3,
              shadowColor: "rgba(0, 0, 0, 0.03)",
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowOffsetY: 30,
            },
          },
        ],
      };
      option && myChart.setOption(option);
    },
  },
};
</script>
<style scoped lang="scss">
#areaMap {
  width: calc(100vw - 192px);
  height: 500px;
}
</style>
