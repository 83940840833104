<template>
  <div class="scanning-anser-sheet">
    <div v-for="(item, index) in list" :key="index" class="scanning-anser-card">
      <div class="sheet-title">{{ item.parentLabel }}</div>
      <div class="sheet-describe">
        <latex :value="item.label"></latex>
      </div>
      <div class="answer-sheet">
        <div v-for="(i, index) in item.orders" :key="index" class="answer-item">
          <div
            class="answer-item-round"
            :class="answerInfos[i - 1].responseStatus == 1 ? 'answer-true' : answerInfos[i - 1].answer == '' ? 'answer-no' : 'answer-false'"
            @click="clickAnswer(i - 1)"
          >
            {{ i }}
            <div class="answer-item-result">
              <text class="iconfont"></text>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'answerSheet',
  props: {
    list: Array,
    answerInfos: Array,
  },
  data() {
    return {
      // list: [
      // 	{
      // 		title: '函数的定义域',
      // 		describe: '题型：已知函数y=f(x)的解析式，且解析式包含分式、根式、一次项、二次项，求函数定义域',
      // 		itemIndex: [3, 10, 11]
      // 	},
      // 	{
      // 		title: '函数的概念辨析',
      // 		describe: '题型：已知x、y的解析式，判断y是否为x的函数',
      // 		itemIndex: [2, 5, 7]
      // 	}
      // ]
    }
  },
  methods: {
    clickAnswer(index) {
      this.$emit('clickAnswer', index)
    },
  },
}
</script>

<style lang="scss" scoped>
.scanning-anser-card {
  padding: 20px 0;
  border-bottom: 1px solid #eeeeee;

  .sheet-title {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 40px;
  }
  .sheet-describe {
    font-size: 16px;
    color: #999999;
    line-height: 32px;
  }
}
.answer-sheet {
  .answer-item {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
    margin-right: 24px;
  }
  .answer-item-round {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;

    &.answer-true {
      background-color: rgba($color: #51c41a, $alpha: 0.1);
      color: #51c41a;
      border: 1px solid #51c41a;
    }
    &.answer-false {
      background-color: rgba($color: #ff0028, $alpha: 0.1);
      color: #ff0028;
      border: 1px solid #ff0028;
    }
  }
}
</style>
