<!--
 * @Author: wyq
 * @Date: 2021-09-29 10:00:53
 * @LastEditTime: 2021-10-11 10:29:30
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\components\report\rankingProgressBar.vue
-->
<template>
  <div class="ranking-progress-bar">
    <div class="main-bar">
      <v-icon class="thumbtack" :style="{left:allPercent}" color="primary" size="32">icon-thumbtack</v-icon>
      <!-- <v-icon
        class="thumbtack"
        :style="{left:classPercent+'%'}"
        color="secondary"
        size="32"
      >icon-thumbtack</v-icon>-->
      <span>25%</span>
      <span>75%</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    allPercent: String,
  },
  data() {
    return {
      classPercent: 80,
    }
  },
}
</script>
<style lang="scss" scoped>
.ranking-progress-bar {
  margin-top: 50px;
  margin-bottom: 20px;
  .main-bar {
    position: relative;
    height: 32px;
    background: linear-gradient(
      90deg,
      #ff3838 25%,
      #ffe900 25%,
      #ffe900 75%,
      #52c41a 0
    );
    .thumbtack {
      position: absolute;
      top: -38px;
      transform: translateX(-50%);
    }
    span {
      position: absolute;
      bottom: -35px;
      font-size: 20px;
      color: #333;
      font-family: Akrobat-SemiBold;
      transform: translateX(-50%);
      &:nth-of-type(1) {
        left: 25%;
      }
      &:nth-of-type(2) {
        left: 75%;
      }
    }
  }
}
</style>