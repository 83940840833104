<!--
 * @Author: wyq
 * @Date: 2021-09-27 09:32:47
 * @LastEditTime: 2021-10-28 15:25:50
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\components\testing\sequenceBar.vue
-->
<template>
  <ul class="sequence-bar" :class="{inline}">
    <li
      v-for="(item,index) in value"
      :key="index"
      :ref="index"
      :class="{finish:index<currentIndex||finish,current:index==currentIndex}"
    >
      <template v-if="preview&&item">
        <v-badge
          v-if="item.responseStatus==1"
          bottom
          color="success"
          icon="icon-success"
          offset-x="10"
          offset-y="20"
        >
          <div class="square" @click="clickSquare(index)">{{index+1}}</div>
        </v-badge>
        <v-badge
          v-else-if="item.responseStatus==2"
          bottom
          overlap
          color="error"
          icon="icon-error"
          offset-x="10"
          offset-y="20"
        >
          <div class="square" @click="clickSquare(index)">{{index+1}}</div>
        </v-badge>
        <v-badge
          v-else-if="item.responseStatus==3"
          bottom
          color="error"
          content="未作答"
          offset-x="10"
          offset-y="20"
        >
          <div class="square" @click="clickSquare(index)">{{index+1}}</div>
        </v-badge>
      </template>

      <div v-else class="square">{{index+1}}</div>
    </li>
  </ul>
</template>
<script>
export default {
  props: {
    inline: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    finish: {
      type: Boolean,
      default: false,
    },
    currentIndex: {
      type: Number,
    },
    value: [Array, Number],
  },
  data() {
    return {}
  },
  watch: {
    currentIndex(val) {
      if (val !== null && val !== undefined) {
        this.$refs[val][0].scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        })
      }
    },
  },
  methods: {
    clickSquare(index) {
      this.$emit('clickSquare', index)
    },
  },
}
</script>
<style lang="scss" scoped>
.sequence-bar {
  padding: 0;
  box-sizing: border-box;
  margin-top: 24px;
  margin-bottom: -24px;
  margin-right: -32px;
  &.inline {
    overflow: auto;
    white-space: nowrap;
    margin: 0;
    li {
      margin: 14px 32px;
    }
  }
  li {
    display: inline-block;
    margin-right: 64px;
    margin-bottom: 32px;
    &.finish .square {
      color: #0148ff;
      border: 1px solid #0148ff;
      background: rgba(0, 74, 255, 0.1);
    }
    &.current .square {
      color: #fff;
      background: #0148ff;
      border: 1px solid #0148ff;
    }
    .square {
      width: 36px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      font-family: PingFangSC-Regular;
      border-radius: 14px;
      border: 1px solid #999999;
      color: #666666;
    }
  }
}
</style>