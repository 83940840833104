<!--
 * @Author: wyq
 * @Date: 2021-09-28 16:17:11
 * @LastEditTime: 2022-04-14 15:38:36
 * @LastEditors: yy
 * @Description: 
 * @FilePath: \cat_student\src\views\testingCenter\catReport.vue
-->
<template>
  <div class="testing-report">
    <div class="report-container">
      <v-card>
        <!-- 绝对定位start -->
        <div class="score-container">
          <div class="score score-ability">
            <div>{{ reportInfo.ability }}</div>
            <p>本次能力得分</p>
          </div>
          <div v-if="reportInfo.lastRecord" class="score score-compare">
            <div class="compare-img">
              <img :src="compareImgUrl" />
              <div class="text-number">{{ compareScore > 0 ? compareScore : -compareScore }}</div>
            </div>
            <p>与上次相比</p>
          </div>
        </div>
        <!-- 绝对定位end -->
        <color-title color="primary" :title="reportInfo.topicLabel"></color-title>
        <div class="report-text">
          <span v-if="reportInfo.testTime">{{ reportInfo.testTime | datefmt('YYYY年M月D日') }}</span>
          <template v-if="reportInfo.costTime">
            <span
              v-if="reportInfo.costTime < $store.state.catTesting.totalTime * 60"
            >总共用时：{{ reportInfo.costTime | formatSeconds }}</span>
            <span v-else>总共用时：{{ $store.state.catTesting.totalTime * 60 | formatSeconds }}</span>
          </template>
        </div>
        <color-title color="accent">测试成绩排名</color-title>
        <ranking-progress-bar :allPercent="rankPercent"></ranking-progress-bar>
        <div class="report-text">
          <img src="../../assets/images/report/icon.png" />
          <span>本次测试成绩</span>
          <!-- <span class="color-secondary">超班级80%同学</span> -->
          <span class="color-primary">超过{{ rankPercent }}的学生</span>
        </div>
      </v-card>
      <v-card>
        <color-title color="secondary">答题一览</color-title>
        <sequence-bar :value="reportInfo.answerInfos" @clickSquare="clickSquare" preview finish></sequence-bar>
        <div class="report-text">
          温馨提示：为了保证每次测量的信度和效度，评价报告
          <span class="color-danger">不显示CAT测试题的答案</span>
        </div>
      </v-card>
      <v-card>
        <color-title color="warning">答题时间曲线</color-title>
        <div class="areaLineTitle">
          <div>平均答题时间曲线</div>
          <div class="line"></div>
          <div>个人答题时间曲线</div>
          <div class="line personalLine"></div>
        </div>
        <areamap :singleTime="reportInfo.singleTime" :averageTime="reportInfo.averageTime" />
      </v-card>
      <v-card class="full-card">
        <color-title color="primary">
          测量进度-{{ reportInfo.topicLabel }}
          <lineProgress :before="reportInfo.beforeProgress" :after="reportInfo.afterProgress"></lineProgress>
        </color-title>
        <tabTree :treeData="treeData" :controlRate="controlRate" :float="true"></tabTree>
      </v-card>
      <v-card class="full-card">
        <color-title color="primary">本次测试知识点扫描</color-title>
        <scanningAnswerList
          :list="reportInfo.knowledgeScan"
          :answerInfos="reportInfo.answerInfos"
          @clickAnswer="clickSquare"
        ></scanningAnswerList>
      </v-card>
    </div>
    <v-btn
      fab
      x-large
      color="primary"
      class="rounded-xl btn-fixed"
      @click="goBack"
      v-if="path == 'academicArchives'"
    >
      <div>返回</div>
    </v-btn>
    <v-btn fab x-large color="primary" class="rounded-xl btn-fixed" @click="finishTest" v-else>
      <div>
        <span>结束</span>
        <br />
        <span>测试</span>
      </div>
    </v-btn>
    <question-result-dialog :questionInfo="currentQuestion" :visible.sync="questionResultVisible"></question-result-dialog>
  </div>
</template>
<script>
import rankingProgressBar from '@/components/report/rankingProgressBar'
import sequenceBar from '@/components/testing/sequenceBar.vue'
// import colorTree from '@/components/colorTree/colorTree'
import tabTree from '@/components/testing/tabTree.vue'
import questionResultDialog from '@/components/dialogs/questionResultDialog'
import areamap from '@/components/echarts/areamap'
import scanningAnswerList from '@/components/testing/scanningAnswerList'
import lineProgress from '@/components/testing/lineProgress'

export default {
  components: {
    rankingProgressBar,
    sequenceBar,
    // colorTree,
    tabTree,
    questionResultDialog,
    areamap,
    scanningAnswerList,
    lineProgress,
  },
  data() {
    return {
      questionResultVisible: false,
      reportInfo: {},
      currentQuestion: {},
      // treeTypeList: [
      //   { id: 323, label: '题型' },
      //   { id: 454, label: '考点' },
      // ],
      currentTreeType: 323,
    }
  },
  computed: {
    compareImgUrl() {
      let result = ''
      if (this.compareScore > 0) {
        result = require('@/assets/images/report/up.png')
      } else if (this.compareScore < 0) {
        result = require('@/assets/images/report/down.png')
      } else {
        result = require('@/assets/images/report/flat.png')
      }
      return result
    },
    testId() {
      return this.$route.query.id
    },
    path() {
      return this.$route.query.path
    },
    rankPercent() {
      let result = ''
      const { allRank, allRankNum } = this.reportInfo
      if (allRank && allRankNum) {
        result = 100 - parseInt((allRank * 100) / allRankNum) + '%'
      } else {
        result = '0%'
      }
      return result
    },
    compareScore() {
      const lastRecord = this.reportInfo.lastRecord
      const lastScore = lastRecord
        ? lastRecord.score
          ? lastRecord.score
          : 0
        : 0
      const currentScore = this.reportInfo.ability
      console.log(lastScore, currentScore)
      return currentScore - lastScore
    },
    treeData() {
      const id = this.currentTreeType
      const trees = this.reportInfo.trees
      if (trees && trees[id]) {
        console.log(id, trees, trees[id])
      }
      return trees? trees.children : []
      // return trees && trees[id]?.children ? trees[id].children : []
    },
    colorNode() {
      const id = this.currentTreeType
      const performance = this.reportInfo.performance
      return performance ? performance[id]?.performance : {}
    },
    controlRate() {
      const controlRate = this.reportInfo.controlRate
      return controlRate ? controlRate : {}
    },
  },
  methods: {
    clickSquare(val) {
      this.questionResultVisible = true
      const questionInfo = this.reportInfo.answerInfos[val]
      this.currentQuestion = {
        responseStatus: questionInfo.responseStatus,
        costTime: questionInfo.costTime,
        ...this.$formattedData(questionInfo),
      }
      // 选择题选项显示对错
      if (questionInfo.type == 1) {
        if (questionInfo.responseStatus == 1) {
          this.$set(
            this.currentQuestion,
            'successOption',
            Number(questionInfo.answer)
          )
        } else if (questionInfo.responseStatus == 2) {
          this.$set(
            this.currentQuestion,
            'errorOption',
            Number(questionInfo.answer)
          )
        }
      }
      console.log(this.currentQuestion)
    },
    finishTest() {
      this.$router.go(-2)
    },
    goBack() {
      this.$router.go(-1)
    },
    async catTestReport() {
      let params = {
        testId: this.testId,
      }

      const res = await this.$service.catTestReport(params)
      const { measurementProgress } = res
      this.reportInfo = {
        ...res,
        beforeProgress: measurementProgress.lightUpNumPast
          ? parseInt(
            (measurementProgress.lightUpNumPast /
              measurementProgress.nodeNum) *
            100
          )
          : 0,
        afterProgress: measurementProgress.lightUpNumNow
          ? parseInt(
            (measurementProgress.lightUpNumNow /
              measurementProgress.nodeNum) *
            100
          )
          : 0,
      }
      this.reportInfo.answerInfos.sort((a, b) => {
        return a.order - b.order
      })
    },
  },
  mounted() {
    this.catTestReport()
  },
}
</script>
<style lang="scss" scoped>
.testing-report {
  overflow: auto;
  .report-container {
    min-height: 100vh;
    padding: 18vw 32px 40px 32px;
    background-image: url("../../assets/images/report/reportBackground.png");
    background-size: 100% auto;
    .v-card {
      padding: 32px 64px;
      border-radius: 48px;
      & + .v-card {
        margin-top: 32px;
      }
      .report-text {
        padding: 24px 0;
        margin-bottom: 24px;
        font-size: 22px;
        color: #999999;
        &:last-of-type {
          margin-bottom: 0;
          padding-bottom: 0;
        }
        img {
          display: inline-block;
          width: 24px;
          height: 24px;
          margin-right: 16px;
          vertical-align: text-bottom;
        }
        span {
          &.color-primary {
            margin-left: 10px;
            color: #0148ff;
          }
          &.color-secondary {
            color: #0dd1ff;
            margin-left: 48px;
          }
          &.color-danger {
            color: #ff3838;
          }
        }
      }
      .score-container {
        position: absolute;
        top: 32px;
        right: 32px;
        border-left: 1px solid #eee;
        border-radius: 0;
        .score {
          display: inline-block;
          width: 120px;
          margin-left: 60px;
          text-align: center;
          vertical-align: middle;
          &-ability {
            font-size: 52px;
            font-family: Akrobat-Black;
          }
          &-compare {
            font-size: 32px;
            font-family: Akrobat-SemiBold;
            .compare-img {
              display: flex;
              justify-content: center;
              align-items: center;
              .text-number {
                display: inline-block;
                margin-left: 4px;
              }
            }
          }
          & > div {
            height: 80px;
          }
          & > p {
            margin-bottom: 0;
            font-size: 18px;
            font-family: PingFangSC-Regular;
            color: #999;
          }
        }
      }
    }
    .areaLineTitle {
      width: calc(100vw - 192px);
      line-height: 26px;
      margin: -26px 0 20px;
      color: #b9b9b9;
      div {
        float: right;
      }
      .line {
        height: 4px;
        background: #ffb13f;
        width: 35px;
        float: right;
        margin: 11px 20px 11px 40px;
      }
      .personalLine {
        background: #0148ff;
      }
    }
  }
  .btn-fixed {
    position: fixed;
    bottom: 70px;
    right: 96px;
    color: #fff;
    font-size: 20px;
    box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.15);
    font-family: PingFangSC-Medium;
    z-index: 105;
  }
}
.v-card > *:last-child:not(.v-btn):not(.v-chip):not(.v-avatar) {
  border-bottom-left-radius: 0 !important;
}
.full-card {
  min-height: calc(100vh - 64px);
}
.color-title-suffix {
  position: absolute;
  right: 0;
  z-index: 100;
  ::v-deep .v-btn {
    height: 34px;
    padding: 0 26px !important;
    line-height: 34px;
    border-radius: 18px !important;
  }
  ::v-deep .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: #0148ff !important;
  }
  ::v-deep .v-btn--active {
    background-color: #0148ff !important;
    .v-btn__content {
      color: #fff;
    }
  }
  ::v-deep .theme--light.v-btn--active:hover::before,
  .theme--light.v-btn--active::before {
    opacity: 0;
  }
}
</style>