import { render, staticRenderFns } from "./questionResultDialog.vue?vue&type=template&id=918a58e8&scoped=true&"
import script from "./questionResultDialog.vue?vue&type=script&lang=js&"
export * from "./questionResultDialog.vue?vue&type=script&lang=js&"
import style0 from "./questionResultDialog.vue?vue&type=style&index=0&id=918a58e8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "918a58e8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBadge,VIcon})
